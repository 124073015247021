import './assets/styles/App.css'
import Gallery from './pages/Gallery'
import About from './pages/About'
import Landing from './pages/Landing'
import Wishlist from './pages/Wishlist'
import { Routes, Route } from 'react-router-dom'
import { WishlistProvider } from './context/WishlistContext'
import { FilterProvider } from './context/FilterContext'

const App = () => {
  return (
    <WishlistProvider>
      <FilterProvider>
        <Routes>
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Landing />} />
          <Route path="/wishlist" element={<Wishlist />} />
        </Routes>
      </FilterProvider>
    </WishlistProvider>
  )
}

export default App