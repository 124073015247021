import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import elikoLogo from '../assets/eliko-logo.png';
import Navbar from '../components/Navbar';
import { WishlistContext } from '../context/WishlistContext';
import Image from '../components/Image';
import Paginate from '../components/Paginate';
import { generatePDF } from '../utils/pdfGenerator';

const Wishlist = () => {
  const { wishlist, clearWishlist } = useContext(WishlistContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [postsPerPage] = useState(8);
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    const footer = document.querySelector('footer');
    setFooterHeight(footer.offsetHeight);

    const handleResize = () => {
      setFooterHeight(footer.offsetHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setCurrentPosts(
      wishlist.slice(
        (currentPage - 1) * postsPerPage,
        currentPage * postsPerPage,
      ),
    );
  }, [currentPage, wishlist, postsPerPage]);

  const handlePageChange = useCallback(
    (pageNumber) => {
      setCurrentPage(pageNumber);
    },
    [setCurrentPage],
  );

  return (
    <div className="wishlist-container" style={{ paddingBottom: footerHeight }}>
      <div className="header-bar">
        <div className="logo">
          <Link to="/">
            <img
              href="/"
              src={elikoLogo}
              alt="Rug Samples Webpage"
              className="logo-img"
            />
          </Link>
        </div>
        <Navbar />
      </div>
      <div className="content">
        <h1 className="wishlist-title">Wishlist</h1>
        <div className="wishlist-actions">
          <div className="wishlist-buttons">
            <button onClick={clearWishlist} className="clear-wishlist-button">
              Clear Wishlist
            </button>
            <button className="save-pdf-button" onClick={() => generatePDF(wishlist)}>
              Save as PDF
            </button>
          </div>
          <p className="wishlist-count">Items: {wishlist.length}</p>
        </div>
        <div id="gallery">
          {currentPosts.map((item) => (
            <Image
              key={item.id}
              img={{
                src: item.src,
                name: item.name,
                color1: item.color1,
                color2: item.color2,
                design: item.design,
                pattern: item.pattern,
                texture: item.texture,
                fiber: item.fiber,
              }}
            />
          ))}
        </div>
        <Paginate
          postsPerPage={postsPerPage}
          totalPosts={wishlist.length}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <footer className="page-footer">
        <div className="footer-content">
          <div className="column">
            <h3 className="footer-header">Company</h3>
            <a href="https://www.elikoruggallery.com" target="_blank" rel="noopener noreferrer">Official Website</a>
          </div>
          <div className="column">
            <h3 className="footer-header">Connect</h3>
            <a href="https://www.instagram.com/elikoruggalleryofficial?igsh=MTdhZ3cxMzBtM3d0aw%3D%3D" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
        <div className="copyright-notice">
          © 2024 Eliko Rug Gallery. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default Wishlist;