import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import elikoLogo from '../assets/eliko-logo.png';
import Navbar from '../components/Navbar';
import { WishlistContext } from '../context/WishlistContext';

const About = () => {
  const [searchParams] = useSearchParams();
  const { wishlist, addToWishlist, removeFromWishlist } = useContext(WishlistContext);
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const sample = useMemo(() => ({
    src: searchParams.get("src"),
    name: searchParams.get("name"),
    color1: searchParams.get("color1"),
    color2: searchParams.get("color2"),
    design: searchParams.get("design"),
    pattern: searchParams.get("pattern"),
    texture: searchParams.get("texture"),
    fiber: searchParams.get("fiber"),
  }), [searchParams]);

  useEffect(() => {
    const checkWishlist = () => {
      const isInWishlist = wishlist.some(item => 
        item.src === sample.src &&
        item.name === sample.name &&
        item.color1 === sample.color1 &&
        item.color2 === sample.color2 &&
        item.design === sample.design &&
        item.pattern === sample.pattern &&
        item.texture === sample.texture &&
        item.fiber === sample.fiber
      );
      setIsInWishlist(isInWishlist);
    };

    checkWishlist();
  }, [wishlist, sample]);

  useEffect(() => {
    const loadImage = async () => {
      const extensions = ['jpg', 'jpeg', 'png'];
      for (const ext of extensions) {
        try {
          const image = await import(`../assets/images/${sample.src}.${ext}`);
          setImageSrc(image.default);
          return;
        } catch (error) {
          console.error(`Failed to load image: ${sample.src}.${ext}`, error);
        }
      }
      setImageSrc(null);
    };

    loadImage();
  }, [sample.src]);

  const handleAddToWishlist = () => {
    addToWishlist({ ...sample, src: sample.src });
    setIsInWishlist(true);
  };

  const handleRemoveFromWishlist = () => {
    removeFromWishlist(sample);
    setIsInWishlist(false);
  };

  return (
    <div className="about-container">
      <div className="header-bar">
        <div className="logo">
          <Link to="/">
            <img
              href="/"
              src={elikoLogo}
              alt="Rug Samples Webpage"
              className="logo-img"
            />
          </Link>
        </div>
        <Navbar />
      </div>
      <div className="sample-box">
        {imageSrc && (
          <img
            src={imageSrc}
            alt={sample.color1}
            className="img-sample"
          />
        )}
        <div className="img-desc">
          <h1>{sample.name}</h1>
          {sample.design && <p><b>Design:</b> {sample.design}</p>}
          {sample.texture && <p><b>Texture:</b> {sample.texture}</p>}
          {sample.color1 && <p><b>Color 1:</b> {sample.color1}</p>}
          {sample.color2 && <p><b>Color 2:</b> {sample.color2}</p>}
          {sample.fiber && <p><b>Fiber:</b> {sample.fiber}</p>}
          {isInWishlist ? (
            <button onClick={handleRemoveFromWishlist} className="wishlist-button remove-from-wishlist-button">
              Remove from Wishlist
            </button>
          ) : (
            <button onClick={handleAddToWishlist} className="wishlist-button add-to-wishlist-button">
              Add to Wishlist
            </button>
          )}
        </div>
      </div>
      <footer className="page-footer">
        <div className="footer-content">
          <div className="column">
            <h3 className="footer-header">Company</h3>
            <a href="https://www.elikoruggallery.com" target="_blank" rel="noopener noreferrer">Official Website</a>
          </div>
          <div className="column">
            <h3 className="footer-header">Connect</h3>
            <a href="https://www.instagram.com/elikoruggalleryofficial?igsh=MTdhZ3cxMzBtM3d0aw%3D%3D" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
        <div className="copyright-notice">
          © 2024 Eliko Rug Gallery. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
};

export default About;