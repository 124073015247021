import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { WishlistContext } from '../context/WishlistContext';

const Navbar = () => {
  const { wishlist } = useContext(WishlistContext);

  return (
    <nav className="navbar">
      <Link to="/" className="nav-link">Home</Link>
      <Link to="/gallery" className="nav-link">Gallery</Link>
      <Link to="/wishlist" className="nav-link cart-icon">
        <i className="fas fa-shopping-cart"></i>
        {wishlist.length > 0 && <span className="cart-badge">{wishlist.length}</span>}
      </Link>

      {/* <Link to="/about" className="nav-link">About</Link> */}
      {/* <Link to="/services" className="nav-link">Services</Link> */}
      {/* <Link to="/contact" className="nav-link">Contact</Link> */}
    </nav>
  );
};
export default Navbar;