import React, { useCallback } from 'react';

const Paginate = React.memo(({ postsPerPage, totalPosts, currentPage, onPageChange }) => {
const maxPages = Math.ceil(totalPosts / postsPerPage);
const displayPages = Math.min(7, maxPages); // Number of pages to display
const middlePage = Math.floor(displayPages / 2);
let startPage = currentPage - middlePage;
let endPage = currentPage + middlePage;

if (maxPages <= displayPages) {
  // If total pages are less than or equal to the desired display pages
  startPage = 1;
  endPage = maxPages;
} else if (currentPage <= middlePage) {
  // If current page is towards the beginning
  startPage = 1;
  endPage = displayPages;
} else if (currentPage >= maxPages - middlePage) {
  // If current page is towards the end
  startPage = maxPages - displayPages + 1;
  endPage = maxPages;
}

const handlePageClick = useCallback((pageNumber) => {
  if (pageNumber !== currentPage) onPageChange(pageNumber);
}, [onPageChange, currentPage]);

const handlePreviousPageClick = useCallback(() => {
  if (currentPage > 1) onPageChange(currentPage - 1);
}, [onPageChange, currentPage]);

const handleNextPageClick = useCallback(() => {
  if (currentPage < maxPages) onPageChange(currentPage + 1);
}, [onPageChange, currentPage, maxPages]);

const pageNumbers = [];
for (let i = startPage; i <= endPage; i++) {
  pageNumbers.push(i);
}

return (
  <div className={`pagination-container ${maxPages === 0 ? 'hidden' : ''}`}>
    <ul className="pagination">
      <li 
        onClick={handlePreviousPageClick} 
        className={`arrow ${currentPage === 1 ? 'disabled' : ''}`}>
        ←
      </li>
      {pageNumbers.map((number) => (
        <li
          key={number}
          onClick={() => handlePageClick(number)}
          className={`page-number ${number === currentPage ? 'current-page' : ''}`}
        >
          {number}
        </li>
      ))}
      <li 
        onClick={handleNextPageClick} 
        className={`arrow ${currentPage === maxPages ? 'disabled' : ''}`}>
        →
      </li>
    </ul>
  </div>
);
});

export default Paginate;
